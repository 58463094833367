
// Libraries
import * as React from 'react'

// Components
import Button from '../components/button'

const RegisterLinks = () => (
	<div className="register-links">
		<p className="title color--endeavour"><strong>Don't have a login?</strong></p>
		<p className="button-wrap">
			<Button to="/register" hollow colorEndeavour xsBlock>Register as Carer</Button>
			<Button to="/register/organisation" hollow colorEndeavour xsBlock>Register Organisation</Button>
		</p>
	</div>
)

export default RegisterLinks
