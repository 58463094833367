
// Libraries
import * as React from 'react'
import { navigate } from 'gatsby'

// Services
import AuthService from '../services/auth'

// Components
import Layout from '../components/layout'
import DuoSection from '../components/duoSection'
import Seo from '../components/seo'
import InputFactory from '../components/inputFactory'
import RegisterLinks from '../components/registerLinks'
import Button from '../components/button'
import { Link }  from 'gatsby'
import BasketContext from "../contexts/basket";
import ApiService from "../services/api";

class LoginPage extends React.Component {
	state = {
		inputValues: {},
		inputErrors: {},
	}

	static contextType = BasketContext

	render() {
		const {inputValues, inputErrors, isLoading} = this.state

		return <Layout className="page--login nav-blue-half">
			<Seo title="Login" />
			<DuoSection login admin-login>
				<div>
					<div className="content-wrap">
						<h1 className="color--endeavour">Login</h1>
						<form onSubmit={this.handleSubmit}>
							<InputFactory
								type="email"
								name="email"
								required
								placeholder="Email"
								value={inputValues['email']}
								error={inputErrors['email']}
								onChange={this.handleInputChange}
								className="text-input"
							/>
							<InputFactory
								type="password"
								name="password"
								required
								placeholder="Password"
								value={inputValues['password']}
								error={inputErrors['password']}
								onChange={this.handleInputChange}
								className="text-input"
							/>
							<p><Link to="/forgotten-password" className="link--text color--endeavour">Forgotten Password?</Link></p>
							<InputFactory
								type="checkbox"
								name="remember_me"
								label="Remember me"
								value={inputValues['remember_me']}
								error={inputErrors['remember_me']}
								onChange={this.handleInputChange}
							/>
							<Button isLoading={isLoading} type="submit" colorEndeavour xsBlock>Login</Button>
						</form>
						<RegisterLinks/>
					</div>
				</div>
				<div>
				</div>
			</DuoSection>
		</Layout>
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (event) => {
		event.preventDefault()
		this.setState({
			isLoading: true
		})
		const {inputValues} = this.state

		const authService = new AuthService()
		await authService.login(inputValues['email'], inputValues['password'], inputValues['remember_me'])
			.then(async (response) => {
				if (response.success) {
					// todo: redirect to where?
					(new ApiService()).get('basket').then((response) => {
						if (response.success) {
							this.context.update(response.data)
						}
					})

					if (await authService.isAdmin(true)) {
						navigate('/admin/dashboard')
					}
					else {
						if (response.data.organisation_id === 1 && !!response.data.trust_id === false) {
							navigate('/login-update-trust');

						}
						else if (response.data.question) {
							navigate('/login-question')
						}
						else  {
							navigate('/ballots')
						}
					}
				} else {
					this.setState({inputErrors: response.errors, isLoading: false});

					if(response?.errors?.banned) {
						navigate('/banned')
					}
				}
			})
			.catch(err => console.error(err))
	}
}

export default LoginPage
